<template>
  <b-table
    id="user-table"
    :fields="fields"
    :items="items"
    :tbody-tr-class="['tableRowClass']"
    show-empty
    small
  >
    <template #empty>
      <h4 class="text-center">{{ $t('administration.no_records') }}</h4>
    </template>
    <template #head()="props">
      <span>{{ $t(props.label) }}</span>
    </template>

    <template #cell(photo)="props">
      <template>
        <b-avatar :src="props.item.photo" size="40" variant="light-primary" />
      </template>
    </template>

    <template #cell(role)="data">
      <div v-for="(item, index) in data.item.role" :key="index" class="mb-25">
        <b-badge variant="success" v-if="item.name">{{
          item.name[$i18n.locale]
        }}</b-badge>
      </div>
    </template>
    <template #cell(tg_username)="props">
      <span v-if="!!props.item.tg_username">
        {{ props.item.tg_username }}
      </span>
    </template>

    <template #cell(username)="props">
      <span v-if="props.item.username">
        {{ props.item.username }}
      </span>
    </template>

    <template #cell(actions)="props">
      <div class="d-flex justify-content-center">
        <b-button
          class="mr-1"
          size="sm"
          variant="warning"
          @click="$router.push(`/users/edit/${props.item.id}`)"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          v-if="$can('delete', 'auth/user')"
          v-b-modal.modal-delete
          size="sm"
          variant="danger"
          @click="deleteUser(props.item.id)"
        >
          <feather-icon icon="TrashIcon" />
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
import { BTable, BButton, BBadge, BAvatar } from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BTable,
    BButton,
    BBadge,
    BAvatar,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        {
          label: this.$t('general.photo'),
          key: 'photo',
          thClass: 'tableHeadClass',
          tdClass: 'text-left',
        },
        {
          label: this.$t('administration.fio'),
          key: 'full_name',
          thClass: 'tableHeadClass',
          tdClass: 'text-left',
        },
        {
          label: 'Логин',
          key: 'username',
          thClass: 'tableHeadClass',
          tdClass: 'text-left',
        },
        {
          label: this.$t('user.role'),
          key: 'role',
          thClass: 'tableHeadClass',
          tdClass: 'text-left',
        },

        {
          label: 'Телеграмм',
          key: 'tg_username',
          thClass: 'tableHeadClass',
          tdClass: 'text-left',
        },
        {
          label: this.$t('message.tableHeader.email'),
          key: 'email',
          thClass: 'tableHeadClass',
          tdClass: 'text-left',
        },
        {
          label: this.$t('general.actions'),
          key: 'actions',
          thClass: 'tableHeadClass',
          tdClass: 'text-left width-150',
        },
      ],
    }
  },

  methods: {
    ...mapActions('users', ['DELETE_USER']),
    async deleteUser(id) {
      const res = await this.$_showAreYouSureModal()

      if (res) {
        try {
          await this.DELETE_USER(id)
          this.$emit('refresh')
          this.$_successToast(this.$t('general.success'))
        } catch (err) {
          this.$_errorToast(this.$t('general.error'))
        }
      }
    },
  },
}
</script>

<style lang="scss">
#user-table {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

#user-table .tableRowClass {
  background: white;
  transform: translateY(5px);
  transition: all 0.5s ease-in-out;
  text-align: center;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  // -webkit-box-shadow:  0 4px 20px 0px lightgrey;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  // -moz-box-shadow:     0 4px 20px 0px lightgrey;  /* Firefox 3.5 - 3.6 */
  // box-shadow:          0 4px 6px 0px lightgrey;
  td {
    border-top: 0;
    padding: 20px !important;

    &:first-child {
      // padding-left: 25px !important;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}

#user-table .tableRowClass:hover {
  transform: translateY(0);
  // -webkit-box-shadow:  0 4px 20px 0px lightgrey;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  // -moz-box-shadow:     0 4px 20px 0px lightgrey;  /* Firefox 3.5 - 3.6 */
  // box-shadow:          0 4px 6px 0px lightgrey;
}

#user-table .tableHeadClass {
  background: none !important;
  border: none !important;
  text-align: left;
  padding-left: 25px !important;
}

#user-table .tableRowClass:hover {
  .tableTitle {
    color: #7367f0 !important;
  }
}

.button-mt {
  margin-top: 25px;
}
</style>
