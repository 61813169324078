<template>
  <div>
    <label for="">{{ $t('search') }} :</label>
    <b-input-group>
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model="searchInput"
        id="search"
        :placeholder="$t('search') + '...'"
      />
    </b-input-group>
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search',
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      searchInput: '',
      timer: null,
    }
  },

  methods: {
    formatPhone(event) {
      if (['Arrow', 'Backspace', 'Shift'].includes(event.key)) {
        this.preventNextIteration = true
        return
      }
      if (this.preventNextIteration) {
        this.preventNextIteration = false
        return
      }
      const [first] = this.searchInput.replace(/-/g, '').match(/(\d{1,12})/g)
      this.phoneValue = first

      // Format display value based on calculated currencyValue
      this.searchInput = this.phoneValue.replace(
        /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/g,
        '+$1-$2-$3-$4-$5',
      )
    },
  },

  watch: {
    value(newValue) {
      this.searchInput = newValue
    },

    searchInput(newvalue) {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.$emit('callback', newvalue)
      }, 500)

      // console.log('timer0', this.timer)
    },
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  },
  components: { BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup },
}
</script>

<style></style>
