<template>
  <div>
    <SearchSlot>
      <template v-slot:search>
        <AppSearchInput @callback="onSearch" />
      </template>
      <template v-slot:button>
        <AddButton @onClick="$router.push(`/users/add`)" />
      </template>
    </SearchSlot>

    <UsersTable :items="users.results" @refresh="refresh" />

    <TablePagination
      :count="users.count"
      @changePaginationValue="changePaginationValue"
    />
  </div>
</template>

<script>
import {
  BAvatar,
  BFormGroup,
  BFormInput,
  BPagination,
  BTable,
  BButton,
  BBadge,
  BRow,
  BCol,
} from 'bootstrap-vue'

import AppSearchInput from '@/components/AppSearchInput.vue'
import TablePagination from '@/components/TablePagination.vue'
import UsersTable from './components/UsersTable.vue'
import AddButton from '@/common/AddButton.vue'
import store from '@/store/index'
import { reactive, computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    BTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BButton,
    BBadge,
    BRow,
    BCol,
    AddButton,
    AppSearchInput,
    TablePagination,
    UsersTable,
  },

  setup() {
    const params = reactive({
      page: 1,
      page_size: 10,
      search: '',
    })
    onMounted(() => {
      refresh()
    })

    const users = computed(() => store.state.users.users)

    const refresh = () => store.dispatch('users/FETCH_USERS', params)

    const onSearch = value => {
      params.search = value
      params.page = 1
      refresh()
    }

    const changePaginationValue = ({ page, page_size }) => {
      params.page = page
      params.page_size = page_size
      refresh()
    }

    return {
      changePaginationValue,
      onSearch,
      refresh,
      params,
      users,
    }
  },
}
</script>
